let initialized = false;
const callbacks: any[] = [];

document.addEventListener('DOMContentLoaded', (value) => {
    run();
});

export const onReady = (callback: any) => {
    if (typeof callback !== 'function') {
        throw Error('Callback must be function.');
    }

    callbacks.push(callback);

    if (document.readyState === 'complete') {
        run();
    }
};

const run = () => {
    if (!initialized) {
        callbacks.forEach((callback) => callback());
    }

    initialized = true;
};
